<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    class="cat--icon"
  >
    <path
      d="M13.6214 9C12.6755 9 8.61422 9.09281 6 13.0289V9C6 6.51844 3.98156 4.5 1.5 4.5C0.671719 4.5 0 5.17172 0 6C0 6.82828 0.671719 7.5 1.5 7.5C2.32687 7.5 3 8.17313 3 9V21C3 22.6547 4.34531 24 6 24H14.25C14.6644 24 15 23.6644 15 23.25V22.5C15 21.6717 14.3283 21 13.5 21H12L18 16.5V23.25C18 23.6644 18.3356 24 18.75 24H20.25C20.6644 24 21 23.6644 21 23.25V13.5872C20.5177 13.7123 20.0208 13.8 19.5 13.8C16.6027 13.8 14.1787 11.7352 13.6214 9V9ZM21 4.5H18L15 1.5V7.8C15 10.2853 17.0147 12.3 19.5 12.3C21.9853 12.3 24 10.2853 24 7.8V1.5L21 4.5ZM17.625 8.25C17.2106 8.25 16.875 7.91438 16.875 7.5C16.875 7.08562 17.2106 6.75 17.625 6.75C18.0394 6.75 18.375 7.08562 18.375 7.5C18.375 7.91438 18.0394 8.25 17.625 8.25ZM21.375 8.25C20.9606 8.25 20.625 7.91438 20.625 7.5C20.625 7.08562 20.9606 6.75 21.375 6.75C21.7894 6.75 22.125 7.08562 22.125 7.5C22.125 7.91438 21.7894 8.25 21.375 8.25Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CatIcon'
}
</script>

<style scoped lang="scss">
.cat--icon {
  fill: currentColor;
}
</style>
