/**
 * MODULE SETTING - AXIOS
 * @file Add axios as plugins to Vue and export it.
 * @author Massimiliano Marzo
 * @date 09/29/2020
 */

import Vue from 'vue'
import VueAxios from 'vue-axios'

import axios from 'axios'

const BASE_URL = 'https://us-central1-hyla-website.cloudfunctions.net/hylaFunc' //'http://localhost:5001/hyla-website/us-central1/hylaFunc'
const BASE_HEADER = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest'
}

const BASE_OPTIONS = {
  baseURL: BASE_URL,
  headers: BASE_HEADER
}

const axiosInstance = axios.create(BASE_OPTIONS)

/**
 * Define the axios object to be used with the Vue Instance
 * @constant axios
 * @type {Object}
 */
export default Vue.use(VueAxios, axiosInstance)
