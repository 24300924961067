<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    class="brand-medium--icon"
  >
    <path
      d="M3.34902 7.26431C3.37715 6.98774 3.26934 6.71118 3.06309 6.52368L0.949023 3.97368V3.59399H7.5209L12.6021 14.7362L17.0693 3.59399H23.3365V3.97368L21.5271 5.70806C21.3725 5.82524 21.2928 6.02212 21.3256 6.21431V18.9643C21.2928 19.1565 21.3725 19.3534 21.5271 19.4706L23.2943 21.2049V21.5846H14.4021V21.2049L16.235 19.4284C16.4131 19.2502 16.4131 19.194 16.4131 18.9221V8.61899L11.3178 21.5518H10.6287L4.70371 8.61899V17.2862C4.65215 17.6518 4.77402 18.0174 5.03184 18.2799L7.41308 21.1674V21.5471H0.663086V21.1721L3.04434 18.2799C3.29746 18.0174 3.41465 17.6471 3.34902 17.2862V7.26431Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'BrandMediumIcon'
}
</script>

<style scoped lang="scss">
.brand-medium--icon {
  fill: currentColor;
}
</style>
