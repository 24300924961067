<template>
  <v-footer padless>
    <v-card
      flat
      tile
      width="100%"
      class="grey darken-3 d-flex align-end footer-card"
      :min-height="$vuetify.breakpoint.smAndDown ? 480 : 360"
    >
      <v-img
        src="~@/assets/images/footer-wave.svg"
        class="footer-wave"
        height="120"
      />
      <v-container>
        <v-row>
          <v-col
            cols="6"
            sm="6"
            md="1"
            class="d-flex align-center"
            :class="
              $vuetify.breakpoint.smAndDown
                ? ['flex-column', 'justify-center']
                : ''
            "
          >
            <HFooterLogoBox @click="scrollTriggerLogo" />
            <HActionButton
              v-if="$vuetify.breakpoint.smAndDown"
              rounded
              color="primary"
              :callToAction="callToAction"
              @setTab="setTabButton"
              @clickedTab="clickedTab"
              class="mx-auto mt-8"
            />
          </v-col>

          <v-col
            cols="6"
            sm="6"
            md="9"
            class="d-flex align-center"
            :class="
              $vuetify.breakpoint.smAndDown
                ? ['justify-center', 'align-end', 'flex-column']
                : ''
            "
          >
            <HActionButton
              :class="
                $vuetify.breakpoint.smAndDown
                  ? 'mx-auto'
                  : index === 0
                  ? 'ml-2'
                  : ''
              "
              color="white"
              text
              rounded
              v-for="(section, index) in sections"
              :key="index"
              :callToAction="section.name"
              :selectedTab="index"
              @setTab="setTabButton"
              @clickedTab="clickedTab"
            />
          </v-col>

          <v-col
            cols="2"
            class="d-flex justify-end align-center"
            v-if="$vuetify.breakpoint.mdAndUp"
          >
            <HActionButton
              rounded
              color="primary"
              :callToAction="callToAction"
              @setTab="setTabButton"
              @clickedTab="clickedTab"
            />
          </v-col>
        </v-row>

        <v-divider class="mb-4 mt-8 footer-divider"></v-divider>

        <v-row class="text-body-2 font-weight-light white--text">
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="3"
            class="d-flex align-center"
            :class="
              $vuetify.breakpoint.smAndDown
                ? ['justify-center', 'pb-0']
                : ['justify-start']
            "
          >
            <HCrafted v-if="$vuetify.breakpoint.smAndDown" />
            <p class="ma-0 pa-0" v-else>{{ copyrightTag }}</p>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="5"
            lg="6"
            class="d-flex align-center justify-center"
            :class="$vuetify.breakpoint.smAndDown ? ['pt-1', 'pb-2'] : ''"
          >
            <p class="ma-0 pa-0" v-if="$vuetify.breakpoint.smAndDown">
              {{ copyrightTag }}
            </p>
            <HCrafted v-else />
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="3"
            class="d-flex align-center"
            :class="
              $vuetify.breakpoint.smAndDown ? 'justify-center' : 'justify-end'
            "
          >
            <HNavLinks :links="links" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-footer>
</template>

<script>
import scrollToMixin from '@/mixins/scrollToMixin'
export default {
  name: 'HFooter',
  mixins: [scrollToMixin],
  components: {
    HNavLinks: () =>
      import(/* webpackChunkName: "footer" */ '@/components/footer/HNavLinks'),
    HCrafted: () =>
      import(/* webpackChunkName: "footer" */ '@/components/footer/HCrafted'),
    HFooterLogoBox: () =>
      import(
        /* webpackChunkName: "footer" */ '@/components/footer/HFooterLogoBox'
      ),
    HActionButton: () =>
      import(
        /* webpackChunkName: "product" */ '@/components/sections/HActionButton'
      )
  },
  data: () => ({
    callToAction: 'request demo',
    companyName: 'Hyla Lab Inc.',
    rightsReserved: 'All rights reserved',
    links: ['terms', 'privacy']
  }),
  computed: {
    copyrightTag() {
      return `
      ©${new Date().getFullYear()} -
      ${this.companyName}
      ${this.rightsReserved}
      `
    }
  },
  methods: {
    setTab(value) {
      this.$emit('setTab', value)
    },
    clickedTab(value) {
      this.$emit('clickedTab', value)
    },
    setTabButton(value) {
      this.setTab(value + 1)
    },
    scrollTriggerLogo() {
      if (!this.$route.meta.static) {
        this.setTab(null)
        this.scrollToSection('#h-hero')
        this.clickedTab(true)
      } else {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.footer-card::v-deep {
  position: relative;

  & .footer-wave {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  & .footer-divider {
    border-color: white !important;
  }

  & .footer-links {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */

    & li:after {
      margin-right: 4px;
      margin-left: 4px;
      content: ' | ';
    }

    & li:last-child:after {
      content: none;
    }

    & a {
      color: white;
      text-decoration: none;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}
</style>
