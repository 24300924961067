<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    class="heart--icon"
  >
    <path
      d="M21.6709 2.93569C19.1021 0.746625 15.2818 1.14037 12.924 3.57319L12.0006 4.52475L11.0771 3.57319C8.72401 1.14037 4.89901 0.746625 2.33026 2.93569C-0.613487 5.44819 -0.768175 9.95756 1.8662 12.681L10.9365 22.0466C11.5225 22.6513 12.474 22.6513 13.06 22.0466L22.1303 12.681C24.7693 9.95756 24.6146 5.44819 21.6709 2.93569V2.93569Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'HeartIcon'
}
</script>

<style scoped lang="scss">
.heart--icon {
  fill: currentColor;
}
</style>
