import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/lib/services/goto'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "privacy" */ '@/views/Home'),
    meta: {
      static: false
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () =>
      import(/* webpackChunkName: "privacy" */ '@/views/Privacy'),
    meta: {
      static: true
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "privacy" */ '@/views/Terms'),
    meta: {
      static: true
    }
  },
  {
    path: '*',
    name: 'Error',
    component: () => import(/* webpackChunkName: "error" */ '@/views/Error'),
    meta: {
      static: true,
      error: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to) => {
    const scrollOptions = {
      duration: 1300,
      offset: 100,
      easing: 'easeInOutCubic'
    }

    if (to.hash && to.meta.static) {
      return goTo(to.hash, scrollOptions)
    }
    return { x: 0, y: 0 }
  },
  routes
})

export default router
