<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    class="coffee--icon"
  >
    <path
      d="M7.20112 16.8001H14.4011C16.3886 16.8001 18.0011 15.1876 18.0011 13.2001H19.2011C21.8486 13.2001 24.0011 11.0476 24.0011 8.4001C24.0011 5.7526 21.8486 3.6001 19.2011 3.6001H4.50112C4.00237 3.6001 3.60112 4.00135 3.60112 4.5001V13.2001C3.60112 15.1876 5.21362 16.8001 7.20112 16.8001ZM19.2011 6.0001C20.5249 6.0001 21.6011 7.07635 21.6011 8.4001C21.6011 9.72385 20.5249 10.8001 19.2011 10.8001H18.0011V6.0001H19.2011ZM20.9899 20.4001H1.81237C0.0273703 20.4001 -0.47513 18.0001 0.46237 18.0001H22.3361C23.2736 18.0001 22.7786 20.4001 20.9899 20.4001V20.4001Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CoffeeIcon'
}
</script>

<style scoped lang="scss">
.coffee--icon {
  fill: currentColor;
}
</style>
