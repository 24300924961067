<template>
  <cookie-law theme="hyla" class="pa-8">
    <template v-slot="props">
      <span class="text-body-1 text-center">
        {{ cookieLaw }}
        <router-link :to="cookieLink.url" class="ml-1">{{
          cookieLink.text
        }}</router-link>
      </span>
      <v-btn
        @click="props.accept"
        color="primary"
        rounded
        depressed
        class="text-body-1 text-capitalize"
        >Accept</v-btn
      >
    </template>
  </cookie-law>
</template>

<script>
export default {
  name: 'HCookieLaw',
  data: () => ({
    cookieLaw: 'We use cookies to ensure you get the best experience.',
    cookieLink: { url: 'privacy', text: 'Learn more' }
  })
}
</script>

<style scoped lang="scss">
.Cookie--hyla {
  background-color: rgb($grey-darken4-rgb, 0.9); //rgba(35, 39, 47, 0.8);
  color: rgb($grey-lighten4-rgb, 1); //#f1f3f6;
}
</style>
