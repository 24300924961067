<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    class="checkbox-on--icon"
  >
    <path
      d="M3 0C1.34315 0 0 1.34315 0 3V21C0 22.6569 1.34315 24 3 24H21C22.6569 24 24 22.6569 24 21V3C24 1.34315 22.6569 0 21 0H3ZM6.1917 12.4349C5.9361 12.1793 5.9361 11.7649 6.1917 11.5092L7.11735 10.5836C7.37295 10.3279 7.78741 10.3279 8.04302 10.5836L10.9091 13.4496L17.0479 7.31084C17.3035 7.05524 17.7179 7.05524 17.9735 7.31084L18.8992 8.23651C19.1548 8.49212 19.1548 8.90655 18.8992 9.16218L11.3719 16.6895C11.1163 16.9451 10.7019 16.9451 10.4462 16.6894L6.1917 12.4349Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CheckboxIcon'
}
</script>

<style scoped lang="scss">
.checkbox-on--icon {
  fill: currentColor;
}
</style>
