<template>
  <div class="h-header">
    <v-app-bar :color="backgroundColor" height="64" fixed>
      <v-container class="d-flex pa-0 align-center header-bar">
        <HHeaderLogoBox
          :isTransparent="this.currentRouteType ? false : isTransparent"
          class="pa-2"
          @click="scrollTriggerLogo"
        />
        <v-tabs
          v-model="activeTabComp"
          background-color="transparent"
          :color="textColor"
          height="64"
          align-with-title
          :class="['h-tabs', scrollClass]"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <v-tab class="pa-0" style="min-width: 0" />
          <v-tab
            class="text-body-1 text-capitalize"
            v-for="(section, index) in sections"
            :key="index"
            @click="scrollTrigger(section.id, index)"
            :to="getRoute(section.id)"
          >
            {{ section.name }}
          </v-tab>
        </v-tabs>
        <v-spacer />
        <HActionButton
          :outlined="this.currentRouteType ? false : isTransparent"
          rounded
          :color="textColor"
          :callToAction="callToAction"
          @setTab="selectTab"
          @clickedTab="clickedTab"
          v-if="$vuetify.breakpoint.mdAndUp"
        />
        <v-app-bar-nav-icon
          v-if="$vuetify.breakpoint.smAndDown"
          @click.stop="drawer = !drawer"
        >
          <v-icon size="20" :color="textColor"> $hamburger </v-icon>
        </v-app-bar-nav-icon>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      top
      temporary
      class="grey lighten-5"
    >
      <v-list>
        <v-list-item class="py-12 d-flex align-center justify-center">
          <HHeaderLogoBox @click="scrollTriggerLogo(true)" />
        </v-list-item>
      </v-list>
      <v-list rounded>
        <v-list-item-group
          v-model="activeTabComp"
          color="primary"
          class="text-center"
        >
          <v-list-item
            v-for="(section, index) in sections"
            :key="index"
            @click="scrollTrigger(section.id, index, true)"
          >
            <v-list-item-title class="text-body-1 text-capitalize">{{
              section.name
            }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import scrollToMixin from '@/mixins/scrollToMixin'
export default {
  name: 'HHeader',
  mixins: [scrollToMixin],
  components: {
    HActionButton: () =>
      import(
        /* webpackChunkName: "product" */ '@/components/sections/HActionButton'
      ),
    HHeaderLogoBox: () =>
      import(
        /* webpackChunkName: "header" */ '@/components/header/HHeaderLogoBox'
      )
  },
  props: {
    activeTab: {
      type: Number
    }
  },
  data: () => ({
    activeTabComp: null,
    callToAction: 'request demo',
    drawer: false,
    isTransparent: true
  }),
  computed: {
    textColor() {
      return this.isTransparent && !this.currentRouteType ? 'white' : 'primary'
    },
    backgroundColor() {
      return this.isTransparent && !this.currentRouteType
        ? 'transparent'
        : 'white'
    },
    scrollClass() {
      return this.isTransparent && !this.currentRouteType
        ? 'scrolled'
        : 'not-scrolled'
    },
    currentRouteType() {
      return this.$route.meta.static
    }
  },
  methods: {
    changeColor() {
      this.isTransparent = !(
        document.body.scrollTop > 20 || document.documentElement.scrollTop > 20
      )
    },
    selectTab(value) {
      this.setTab(value + 1)
    },
    clickedTab(value) {
      this.$emit('clickedTab', value)
    },
    setTab(value) {
      this.$emit('setTab', value)
    },
    scrollTriggerLogo(mobile = false) {
      if (!this.$route.meta.static) {
        this.setTab(null)
        this.scrollToSection('#h-hero')
        this.clickedTab(true)
        if (mobile) {
          this.drawer = false
        }
      } else {
        this.$router.push('/')
      }
    },
    scrollTrigger(value, index, mobile = false) {
      let tab = mobile ? index : index + 1
      this.setTab(tab)
      this.scrollToSection(value)
      this.clickedTab(true)
    },
    getRoute(sectionId) {
      return this.$route.meta.static ? `/${sectionId}` : ''
    }
  },
  watch: {
    activeTab: function () {
      this.activeTabComp = this.activeTab
      this.drawer = false
    }
  },
  created() {
    window.onscroll = () => {
      if (!this.currentRouteType) {
        this.changeColor()
      }
    }
    window.onpopstate = () => {
      if (!this.currentRouteType) {
        this.isTransparent = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.h-header::v-deep {
  z-index: 10 !important;

  & .header-bar {
    & .h-tabs {
      &.scrolled {
        & .v-tab:not(.v-tab--active) {
          color: white !important;
        }
      }

      &.not-scrolled {
        & .v-tab:not(.v-tab--active) {
          color: var(--v-primary-base) !important;
        }
      }
    }
  }
}
</style>
