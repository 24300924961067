import { setInteractionMode } from 'vee-validate'

setInteractionMode('custom', ({ errors }) => {
  if (errors.length) {
    return {
      on: ['blur']
    }
  }

  return { on: ['blur'] }
})
