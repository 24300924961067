<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    class="hamburger--icon"
  >
    <path
      d="M21.9375 5.8125H2.0625C1.75186 5.8125 1.5 5.56064 1.5 5.25V3.75C1.5 3.43936 1.75186 3.1875 2.0625 3.1875H21.9375C22.2481 3.1875 22.5 3.43936 22.5 3.75V5.25C22.5 5.56064 22.2481 5.8125 21.9375 5.8125ZM21.9375 13.3125H2.0625C1.75186 13.3125 1.5 13.0606 1.5 12.75V11.25C1.5 10.9394 1.75186 10.6875 2.0625 10.6875H21.9375C22.2481 10.6875 22.5 10.9394 22.5 11.25V12.75C22.5 13.0606 22.2481 13.3125 21.9375 13.3125ZM21.9375 20.8125H2.0625C1.75186 20.8125 1.5 20.5606 1.5 20.25V18.75C1.5 18.4394 1.75186 18.1875 2.0625 18.1875H21.9375C22.2481 18.1875 22.5 18.4394 22.5 18.75V20.25C22.5 20.5606 22.2481 20.8125 21.9375 20.8125Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'HamburgerIcon'
}
</script>

<style scoped lang="scss">
.hamburger--icon {
  fill: currentColor;
}
</style>
