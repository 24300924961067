<template>
  <v-app>
    <HHeader
      :activeTab="activeTab"
      @setTab="setActiveTab"
      @clickedTab="setClickedTab"
      v-if="!isError"
    />
    <v-main>
      <router-view
        @setTab="setActiveTab"
        :isTabClicked="isTabClicked"
        @clickedTab="setClickedTab"
      />
    </v-main>
    <HFooter
      @setTab="setActiveTab"
      @clickedTab="setClickedTab"
      v-if="!isError"
    />
    <HCookieLaw v-if="!isError" />
  </v-app>
</template>

<script>
import HHeader from '@/components/HHeader'
import HFooter from '@/components/HFooter'
import HCookieLaw from '@/components/HCookieLaw'
export default {
  name: 'App',
  components: {
    HHeader,
    HFooter,
    HCookieLaw
  },
  data: () => ({
    activeTab: null,
    isTabClicked: false
  }),
  computed: {
    isError() {
      return this.$route.meta.error
    }
  },
  methods: {
    setActiveTab(value) {
      this.activeTab = value
    },
    setClickedTab(value) {
      if (this.isTabClicked !== value) {
        this.isTabClicked = value
      }
    }
  }
}
</script>
