/**
 * Capitalize only the first letter of a text
 *
 * @param text string Text to capitalize first letter
 * @return string First Letter Capitalized text
 */
export const capitalizeOnlyFirst = (text) => {
  return text
    .split(' ')
    .map((word, index) =>
      index === 0
        ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        : word.toLowerCase()
    )
    .join(' ')
}

/**
 * Decamelize a string with/without a custom separator (underscore by default).
 *
 * @param str String in camelcase
 * @param separator Separator for the new decamelize string.
 */
export const decamelizeString = (str, separator) => {
  separator = typeof separator === 'undefined' ? '_' : separator

  return str
    .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
    .toLowerCase()
}
