<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    class="brand-twitter--icon"
  >
    <path
      d="M21.533 7.70086C21.5482 7.91405 21.5482 8.12728 21.5482 8.34047C21.5482 14.843 16.599 22.3354 7.5533 22.3354C4.76648 22.3354 2.17767 21.5282 0 20.1273C0.395953 20.1729 0.776625 20.1882 1.18781 20.1882C3.48727 20.1882 5.60405 19.4116 7.29441 18.0867C5.13197 18.041 3.31978 16.6247 2.69541 14.6755C3 14.7211 3.30455 14.7516 3.62437 14.7516C4.06598 14.7516 4.50764 14.6907 4.91878 14.5841C2.66498 14.1272 0.974578 12.1476 0.974578 9.75671V9.69581C1.62937 10.0613 2.39086 10.2897 3.19791 10.3201C1.87303 9.43688 1.00505 7.92928 1.00505 6.22369C1.00505 5.31 1.24866 4.47244 1.67508 3.74147C4.09641 6.72624 7.73602 8.67544 11.8172 8.88867C11.7411 8.52319 11.6954 8.14252 11.6954 7.7618C11.6954 5.05111 13.8883 2.84302 16.6141 2.84302C18.0304 2.84302 19.3095 3.43692 20.208 4.39631C21.3197 4.18313 22.3857 3.77194 23.3299 3.2085C22.9643 4.35066 22.1877 5.31005 21.1674 5.91914C22.1573 5.8126 23.1167 5.53842 23.9999 5.15775C23.33 6.13233 22.4924 7.00031 21.533 7.70086V7.70086Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'BrandTwitterIcon'
}
</script>

<style scoped lang="scss">
.brand-twitter--icon {
  fill: currentColor;
}
</style>
