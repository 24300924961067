import icons from '@/icons'

export default {
  chevronRight: {
    component: icons.ChevronRightIcon
  },
  heart: {
    component: icons.HeartIcon
  },
  coffee: {
    component: icons.CoffeeIcon
  },
  brandTwitter: {
    component: icons.BrandTwitterIcon
  },
  brandMedium: {
    component: icons.BrandMediumIcon
  },
  brandLinkedin: {
    component: icons.BrandLinkedinIcon
  },
  dot: {
    component: icons.DotIcon
  },
  checkboxOn: {
    component: icons.CheckboxOnIcon
  },
  checkboxOff: {
    component: icons.CheckboxOffIcon
  },
  hamburger: {
    component: icons.HamburgerIcon
  },
  cat: {
    component: icons.CatIcon
  },
  chevronDown: {
    component: icons.ChevronDownIcon
  }
}
