var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{attrs:{"padless":""}},[_c('v-card',{staticClass:"grey darken-3 d-flex align-end footer-card",attrs:{"flat":"","tile":"","width":"100%","min-height":_vm.$vuetify.breakpoint.smAndDown ? 480 : 360}},[_c('v-img',{staticClass:"footer-wave",attrs:{"src":require("@/assets/images/footer-wave.svg"),"height":"120"}}),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",class:_vm.$vuetify.breakpoint.smAndDown
              ? ['flex-column', 'justify-center']
              : '',attrs:{"cols":"6","sm":"6","md":"1"}},[_c('HFooterLogoBox',{on:{"click":_vm.scrollTriggerLogo}}),(_vm.$vuetify.breakpoint.smAndDown)?_c('HActionButton',{staticClass:"mx-auto mt-8",attrs:{"rounded":"","color":"primary","callToAction":_vm.callToAction},on:{"setTab":_vm.setTabButton,"clickedTab":_vm.clickedTab}}):_vm._e()],1),_c('v-col',{staticClass:"d-flex align-center",class:_vm.$vuetify.breakpoint.smAndDown
              ? ['justify-center', 'align-end', 'flex-column']
              : '',attrs:{"cols":"6","sm":"6","md":"9"}},_vm._l((_vm.sections),function(section,index){return _c('HActionButton',{key:index,class:_vm.$vuetify.breakpoint.smAndDown
                ? 'mx-auto'
                : index === 0
                ? 'ml-2'
                : '',attrs:{"color":"white","text":"","rounded":"","callToAction":section.name,"selectedTab":index},on:{"setTab":_vm.setTabButton,"clickedTab":_vm.clickedTab}})}),1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"2"}},[_c('HActionButton',{attrs:{"rounded":"","color":"primary","callToAction":_vm.callToAction},on:{"setTab":_vm.setTabButton,"clickedTab":_vm.clickedTab}})],1):_vm._e()],1),_c('v-divider',{staticClass:"mb-4 mt-8 footer-divider"}),_c('v-row',{staticClass:"text-body-2 font-weight-light white--text"},[_c('v-col',{staticClass:"d-flex align-center",class:_vm.$vuetify.breakpoint.smAndDown
              ? ['justify-center', 'pb-0']
              : ['justify-start'],attrs:{"cols":"12","sm":"12","md":"4","lg":"3"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('HCrafted'):_c('p',{staticClass:"ma-0 pa-0"},[_vm._v(_vm._s(_vm.copyrightTag))])],1),_c('v-col',{staticClass:"d-flex align-center justify-center",class:_vm.$vuetify.breakpoint.smAndDown ? ['pt-1', 'pb-2'] : '',attrs:{"cols":"12","sm":"12","md":"5","lg":"6"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('p',{staticClass:"ma-0 pa-0"},[_vm._v(" "+_vm._s(_vm.copyrightTag)+" ")]):_c('HCrafted')],1),_c('v-col',{staticClass:"d-flex align-center",class:_vm.$vuetify.breakpoint.smAndDown ? 'justify-center' : 'justify-end',attrs:{"cols":"12","sm":"12","md":"3"}},[_c('HNavLinks',{attrs:{"links":_vm.links}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }