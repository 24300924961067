import { required, email, max } from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
import { decamelizeString } from '@/helpers/utils'
import PhoneNumber from 'awesome-phonenumber'

extend('required', {
  ...required,
  //message: 'The {_field_} is required'
  message: (fieldName) => {
    return `The ${decamelizeString(fieldName, ' ')} is required`
  }
})

extend('permission', {
  ...required,
  message: 'The permission must be granted'
})

extend('email', {
  ...email,
  //message: 'This field must be a valid {_field_}'
  message: (fieldName) => {
    return `This field must be a valid ${decamelizeString(fieldName, ' ')}`
  }
})

extend('max', {
  ...max,
  //message: 'The {_field_} may not be greater than {length} characters',
  message: (fieldName, placeholders) => {
    return `The ${decamelizeString(fieldName, ' ')} may not be greater than ${
      placeholders.length
    } characters`
  }
})

extend('url', {
  validate(value) {
    if (value) {
      return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\\u00a1-\\uffff][a-z0-9\\u00a1-\\uffff_-]{0,62})?[a-z0-9\\u00a1-\\uffff]\.)+(?:[a-z\\u00a1-\\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/.test(
        value
      )
    }

    return false
  },
  message: (fieldName) => {
    return `The ${decamelizeString(fieldName, ' ')} must be a valid url`
  }
})

extend('phone', {
  validate(value) {
    console.log(value)
    if (value) {
      return new Promise((resolve) => {
        let phone = new PhoneNumber(value)
        console.log(phone)
        resolve({ valid: phone.isValid() })
      })
    }

    return false
  },
  message: (fieldName) => {
    return `The ${decamelizeString(fieldName, ' ')} is not a valid number`
  }
})
