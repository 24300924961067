<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    class="chevron-right--icon"
  >
    <path
      d="M7.96512 3.48831L7.19172 4.26167C7.00867 4.44472 7.00867 4.74152 7.19172 4.9246L14.2503 12L7.19172 19.0754C7.00867 19.2584 7.00867 19.5552 7.19172 19.7383L7.96512 20.5117C8.14817 20.6948 8.44496 20.6948 8.62805 20.5117L16.8083 12.3315C16.9913 12.1484 16.9913 11.8516 16.8083 11.6685L8.62805 3.48831C8.44496 3.30523 8.14817 3.30523 7.96512 3.48831Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ChevronRightIcon'
}
</script>

<style scoped lang="scss">
.chevron-right--icon {
  fill: currentColor;
}
</style>
