<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    class="checkbox-off--icon"
  >
    <path
      d="M24 2C24 0.895431 23.1046 0 22 0H2C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V2ZM23 3V21C23 22.1046 22.1046 23 21 23H3C1.89543 23 1 22.1046 1 21V3C1 1.89543 1.89543 1 3 1H21C22.1046 1 23 1.89543 23 3Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CheckboxOffIcon'
}
</script>

<style scoped lang="scss">
.checkbox-off--icon {
  fill: currentColor;
}
</style>
