import ChevronRightIcon from '@/icons/ChevronRightIcon'
import CoffeeIcon from '@/icons/CoffeeIcon'
import HeartIcon from '@/icons/HeartIcon'
import BrandLinkedinIcon from '@/icons/BrandLinkedinIcon'
import BrandMediumIcon from '@/icons/BrandMediumIcon'
import BrandTwitterIcon from '@/icons/BrandTwitterIcon'
import DotIcon from '@/icons/DotIcon'
import CheckboxOnIcon from '@/icons/CheckboxOnIcon'
import CheckboxOffIcon from '@/icons/CheckboxOffIcon'
import HamburgerIcon from '@/icons/HamburgerIcon'
import CatIcon from '@/icons/CatIcon'
import ChevronDownIcon from '@/icons/ChevronDownIcon'

export default {
  ChevronRightIcon,
  CoffeeIcon,
  HeartIcon,
  BrandLinkedinIcon,
  BrandMediumIcon,
  BrandTwitterIcon,
  DotIcon,
  CheckboxOnIcon,
  CheckboxOffIcon,
  HamburgerIcon,
  CatIcon,
  ChevronDownIcon
}
