<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    class="brand-linkedin--icon"
  >
    <path
      d="M6.20062 22.4998H1.84688V8.47946H6.20062V22.4998ZM4.02141 6.56696C2.62922 6.56696 1.5 5.41384 1.5 4.02165C1.5 3.35293 1.76565 2.7116 2.2385 2.23875C2.71136 1.76589 3.35269 1.50024 4.02141 1.50024C4.69012 1.50024 5.33145 1.76589 5.80431 2.23875C6.27716 2.7116 6.54281 3.35293 6.54281 4.02165C6.54281 5.41384 5.41313 6.56696 4.02141 6.56696ZM22.4953 22.4998H18.1509V15.6748C18.1509 14.0482 18.1181 11.9623 15.8873 11.9623C13.6237 11.9623 13.2769 13.7295 13.2769 15.5576V22.4998H8.92781V8.47946H13.1034V10.392H13.1644C13.7456 9.2904 15.1655 8.1279 17.2838 8.1279C21.69 8.1279 22.5 11.0295 22.5 14.7982V22.4998H22.4953Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'BrandLinkedinIcon'
}
</script>

<style scoped lang="scss">
.brand-linkedin--icon {
  fill: currentColor;
}
</style>
