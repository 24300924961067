const palette = {
  primary: {
    base: '#5397FF', //500
    darken1: '#1B75FF', //600
    darken2: '#0059E1', //700
    darken3: '#0045AD', //800
    darken4: '#00378A', //900
    lighten1: '#80B0FF', //400
    lighten2: '#98BFFF', //300
    lighten3: '#BAD5FF', //200
    lighten4: '#DDEAFF', //100
    lighten5: '#EBF2FF' //50 #ff6584
  },
  secondary: {
    base: '#526CFF', //500
    darken1: '#193CFF', //600
    darken2: '#0023E0', //700
    darken3: '#001BAD', //800
    darken4: '#00158A', //900
    lighten1: '#8090FF', //400
    lighten2: '#99A7FF', //300
    lighten3: '#B8C1FF', //200
    lighten4: '#DBE0FF', //100
    lighten5: '#EBEDFF' //50
  },
  tertiary: {
    base: '#13BEB8', //500
    darken1: '#10A29C', //600
    darken2: '#0D8581', //700
    darken3: '#0A6965', //800
    darken4: '#084C4A', //900
    lighten1: '#42CBC6', //400
    lighten2: '#71D8D4', //300
    lighten3: '#A1E5E3', //200
    lighten4: '#D0F2F1', //100
    lighten5: '#DBF5F4' //50
  },
  quaternary: {
    base: '#52C6FF', //500
    darken1: '#1AB3FF', //600
    darken2: '#0096DF', //700
    darken3: '#0076AF', //800
    darken4: '#005D8A', //900
    lighten1: '#80D2FF', //400
    lighten2: '#99DBFF', //300
    lighten3: '#B8E6FF', //200
    lighten4: '#DBF3FF', //100
    lighten5: '#EBF8FF' //50
  },
  grey: {
    base: '#CBCDD1', //500
    darken1: '#93989F', //600
    darken2: '#46494e', //700
    darken3: '#323539', //800
    darken4: '#0a0a0b', //900
    lighten1: '#E3E5E7', //400
    lighten2: '#ecedee', //300
    lighten3: '#f4f5f5', //200
    lighten4: '#F7F7F7', //100
    lighten5: '#fafafa' //50
  },
  accent: {
    base: '#E73C7E', //500
    darken1: '#D81B64', //600
    darken2: '#AD1550', //700
    darken3: '#82103C', //800
    darken4: '#560B28', //900
    lighten1: '#EC6398', //400
    lighten2: '#F18AB2', //300
    lighten3: '#F5B1CB', //200
    lighten4: '#FAD8E5', //100
    lighten5: '#FCE4ED' //50
  },
  error: {
    base: '#B71D29', //500
    darken1: '#9C1923', //600
    darken2: '#80141D', //700
    darken3: '#651017', //800
    darken4: '#490C10', //900
    lighten1: '#DC2433', //400
    lighten2: '#E24B57', //300
    lighten3: '#E9727B', //200
    lighten4: '#EF989F', //100
    lighten5: '#F1A7AD' //50
  },
  info: {
    base: '#2391EB', //500
    darken1: '#137CD2', //600
    darken2: '#1066AD', //700
    darken3: '#0C5088', //800
    darken4: '#093B63', //900
    lighten1: '#44A2EE', //400
    lighten2: '#65B2F1', //300
    lighten3: '#86C3F4', //200
    lighten4: '#A7D3F7', //100
    lighten5: '#B4DAF8' //50
  },
  success: {
    base: '#3DB971', //500
    darken1: '#349D60', //600
    darken2: '#2B824F', //700
    darken3: '#22663E', //800
    darken4: '#184A2D', //900
    lighten1: '#56C786', //400
    lighten2: '#74D19B', //300
    lighten3: '#92DBB0', //200
    lighten4: '#B0E5C6', //100
    lighten5: '#BAE8CD' //50
  },
  warning: {
    base: '#F6D41E', //500
    darken1: '#E2BF09', //600
    darken2: '#BA9E07', //700
    darken3: '#927C06', //800
    darken4: '#6A5A04', //900
    lighten1: '#F7DA40', //400
    lighten2: '#F9E162', //300
    lighten3: '#FAE783', //200
    lighten4: '#FBEEA5', //100
    lighten5: '#FCF1B6' //50
  }
}

export default {
  dark: palette, //dark,
  light: palette //light
}
