import sectionsData from '@/constants/sectionsData'
export default {
  data: () => ({
    scrollOptionsFirst: {
      duration: 1400,
      offset: 100,
      easing: 'linear'
    },
    scrollOptionsSecond: {
      duration: 800,
      offset: 100,
      easing: 'easeInCubic'
    },
    sections: sectionsData
  }),
  methods: {
    scrollToSection(ref) {
      this.$vuetify
        .goTo(ref, this.scrollOptionsFirst)
        .then(() =>
          this.$vuetify
            .goTo(ref, this.scrollOptionsSecond)
            .then(() => this.$emit('clickedTab', true))
        )
    }
  }
}
