import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'

import '@/libraries/vee-validate/vee-validate'
import vuetify from '@/plugins/vuetify'
import typedjs from '@/libraries/vue-typedjs'
import axios from '@/libraries/axios'
import cookie from '@/libraries/vue-cookie-law'
import lottie from '@/libraries/lottie-web-vue'
import gtag from '@/libraries/vue-gtag'
import gtm from '@/libraries/vue-gtm'

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  typedjs,
  axios,
  cookie,
  lottie,
  gtag,
  gtm,
  render: (h) => h(App)
}).$mount('#app')
