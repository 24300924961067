<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    class="dot--icon"
  >
    <circle cx="12" cy="12" r="6" />
  </svg>
</template>

<script>
export default {
  name: 'DotIcon'
}
</script>

<style scoped lang="scss">
.dot--icon {
  fill: currentColor;
}
</style>
