import Vue from 'vue'
import VueGtag from 'vue-gtag'
import router from '@/router'

const config = {
  id: 'G-VGW28VCW7W'
}

export default Vue.use(
  VueGtag,
  { config, appName: 'Hyla Website', pageTrackerScreenviewEnabled: true },
  router
)
